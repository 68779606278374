import { Injectable } from '@angular/core';
import { Tenant } from '@core/models';

import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MatchingTenantsState {
	private _matchingTenantsList$ = new BehaviorSubject<Tenant[]>(null);

	get matchingTenantsList$(): Observable<Tenant[]> {
		return this._matchingTenantsList$.asObservable();
	}

	setMatchingTenantsList(tenantList: Tenant[]): void {
		this._matchingTenantsList$.next(tenantList);
	}
}
