import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { environment } from '@environments/environment';
import { CommonItem, Tenant } from '@core/models';
import { TenantViewDateStatus } from '@core/enums';
import { CommonFacade } from '@core/facades/common.facade';
import { Observable } from 'rxjs';

interface DialogData {
	tenant: Tenant;
}

@Component({
	templateUrl: './tenant-view-date-summary-dialog.component.html',
	styleUrl: './tenant-view-date-summary-dialog.component.scss',
})
export class TenantViewDateSummaryDialogComponent {
	constructor(
		@Inject(MAT_DIALOG_DATA) public data: DialogData,
		private dialogRef: MatDialogRef<TenantViewDateSummaryDialogComponent>,
		private commonFacade: CommonFacade
	) {}

	readonly defaultAvatarImg = environment.defaultAvatarImg;
	availibilitySlots$: Observable<CommonItem[]> = this.commonFacade.availiblitySlots$;

	onConfirmViewDate(): void {
		this.dialogRef.close({ status: TenantViewDateStatus.CONFIRM });
	}

	onEditViewDate(): void {
		this.dialogRef.close({ status: TenantViewDateStatus.EDIT });
	}
}
