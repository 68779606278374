<div class="container px-0" *ngIf="{ availiblitySlots: availibilitySlots$ | async } as vm">
	<div class="row align-items-center justify-content-between border-bottom py-10px px-3">
		<div class="col">
			<h4 class="fg-general-5 heading-4-regular">{{ 'n3656' | translations }}</h4>
		</div>

		<div class="col-auto">
			<button class="bg-general-1" mat-dialog-close class="close-btn">
				<mat-icon class="justify-content-center fg-general-4 mx-auto fs-20px">close</mat-icon>
			</button>
		</div>
	</div>

	<button hidden cdkFocusInitial></button>

	<mat-dialog-content class="p-3">
		<div class="d-sm-block" [ngClass]="!isShowTimeSlotsMobile ? 'd-block' : 'd-none'">
			<div class="row gap-1 justify-content-start align-items-center d-flex-sm">
				<div class="col-auto position-relative avatar">
					<img [ngSrc]="data?.tenant?.picture || defaultAvatarImg" fill alt="Tenant Image" class="tenant-img d-block" />

					<img [ngSrc]="'assets/images/place-an-ad/on-hunt-badge.webp'" alt="on hunt badge" class="on-hunt-badge" fill />
				</div>

				<div class="col">
					<h4 class="fg-general-5 heading-4-medium limit-2">{{ data?.tenant?.title }}</h4>

					<div class="d-flex gap-1 mt-2">
						<p class="fg-general-3 text-caption-regular">{{ 'n3650' | translations }}</p>

						<p class="fg-general-5 text-caption-regular limit-2 me-3">{{ data?.tenant?.employment?.jobTitle }}</p>
					</div>

					<div class="row gap-1 align-items-start mt-2">
						<div class="col-auto">
							<p class="fg-general-3 text-caption-regular">{{ 'n3651' | translations }}</p>
						</div>

						<div class="col">
							<p class="fg-general-5 text-caption-regular">{{ data?.tenant?.employment?.grossIncome?.name }}</p>
						</div>
					</div>
				</div>
			</div>

			<p class="fg-general-4 text-paragraph-regular mt-3 mb-2 d-sm-block">
				{{ 'n3668' | translations }}
			</p>
		</div>
		<!-- For Mobile view Time slots -->
		<div class="d-sm-none" [ngClass]="isShowTimeSlotsMobile ? 'd-block' : 'd-none'">
			<button mat-button color="primary" class="text-body-regular d-sm-none back-btn" (click)="onBackToCalendar()">
				<mat-icon class="material-icons fg-prim-2 align-middle">arrow_back</mat-icon>
				<span>{{ 'n3666' | translations }}</span>
			</button>

			<p class="fg-general-4 text-paragraph-regular mt-3 mb-2 d-sm-none">
				{{ 'n3668' | translations }}
			</p>
		</div>
		<!--  -->

		<div class="row gap-3">
			<div class="col-sm d-sm-block position-relative" [ngClass]="!isShowTimeSlotsMobile ? 'd-block' : 'd-none'">
				<mat-calendar
					[(selected)]="selectedDate"
					[dateFilter]="dateFilter"
					[minDate]="todayDate"
					[maxDate]="maxDate"
					class="tenant-calendar"
					(selectedChange)="onSelectDate()"
				></mat-calendar>
			</div>

			<div class="col-sm-auto d-sm-block mw-sm-260px mt-2" [ngClass]="isShowTimeSlotsMobile ? 'd-block' : 'd-none'">
				<mat-button-toggle-group class="w-100" [formControl]="timeSlotControl">
					<div class="row row-cols-2 slot-row">
						<div
							class="col col-sm-auto mb-4"
							*ngFor="let slot of vm?.availiblitySlots | tenantTimeSlots : selectedDate : data?.tenant?.availabilitySlots; let i = index"
							[ngClass]="i % 2 !== 0 ? 'ps-3 ps-sm-0' : ''"
						>
							<mat-button-toggle
								class="rounded-pill w-100 w-sm-auto fg-general-4 d-block time-slot-btn"
								[disableRipple]="true"
								[disabled]="!isShowTimeSlotsMobile"
								[value]="slot?.id"
								type="button"
							>
								{{ slot.name }}
							</mat-button-toggle>
						</div>
					</div>
				</mat-button-toggle-group>
			</div>
		</div>
	</mat-dialog-content>

	<mat-dialog-actions class="p-3 border-top">
		<button
			mat-flat-button
			color="primary"
			class="text-body-regular w-100"
			type="submit"
			[disabled]="!selectedDate || !timeSlotControl.valid"
			(click)="onSetViewDate()"
		>
			{{ 'n3667' | translations }}
		</button>
	</mat-dialog-actions>
</div>
