<div class="container px-0" *ngIf="{ availibilitySlots: availibilitySlots$ | async } as vm">
	<div class="row align-items-center justify-content-between border-bottom py-10px px-3">
		<div class="col">
			<h4 class="fg-general-5 heading-4-regular">{{ 'n3656' | translations }}</h4>
		</div>

		<div class="col-auto">
			<button class="bg-general-1" mat-dialog-close class="close-btn">
				<mat-icon class="justify-content-center fg-general-4 mx-auto fs-20px">close</mat-icon>
			</button>
		</div>
	</div>

	<button hidden cdkFocusInitial></button>

	<mat-dialog-content class="p-3">
		<p>{{ 'n3657' | translations }}</p>

		<div class="row gap-2 justify-content-start align-items-center my-3">
			<div class="col-auto position-relative avatar">
				<img [ngSrc]="data?.tenant?.picture || defaultAvatarImg" fill alt="Tenant Image" class="tenant-img d-block" />

				<img [ngSrc]="'assets/images/place-an-ad/on-hunt-badge.webp'" alt="on hunt badge" class="on-hunt-badge" fill />
			</div>

			<div class="col">
				<h4 class="fg-general-5 heading-4-medium limit-2">{{ data?.tenant?.title }}</h4>

				<div class="d-flex gap-1 mt-2">
					<p class="fg-general-3 text-caption-regular">{{ 'n3650' | translations }}</p>

					<p class="fg-general-5 text-caption-regular limit-2 me-3">{{ data?.tenant?.employment?.jobTitle }}</p>
				</div>

				<div class="row gap-1 align-items-start mt-2">
					<div class="col-auto">
						<p class="fg-general-3 text-caption-regular">{{ 'n3651' | translations }}</p>
					</div>

					<div class="col">
						<p class="fg-general-5 text-caption-regular">{{ data?.tenant?.employment?.grossIncome?.name }}</p>
					</div>
				</div>
			</div>
		</div>

		<div class="view-date p-2">
			<p class="text-body-regular fg-general-4 mb-1">
				{{ 'n3647' | translations }}
				<span class="text-body-medium fg-general-5 ms-1">{{ +data?.tenant.viewAppointment?.viewingAt * 1000 | date : 'mediumDate' }}</span>
			</p>

			<p class="text-body-regular fg-general-4">
				{{ 'n3647' | translations }}
				<span class="text-body-medium fg-general-5 ms-1">{{ vm?.availibilitySlots | tenantSummaryTimeSlot : data?.tenant.viewAppointment?.slotTime?.id }}</span>
			</p>
		</div>
	</mat-dialog-content>

	<mat-dialog-actions class="p-3 border-top">
		<div class="row w-100 justify-content-between gap-3">
			<div class="col">
				<button mat-stroked-button color="primary" class="text-body-regular w-100" (click)="onEditViewDate()">
					{{ 'n3658' | translations }}
				</button>
			</div>

			<div class="col">
				<button mat-flat-button color="primary" class="text-body-regular w-100" (click)="onConfirmViewDate()">
					{{ 'n3659' | translations }}
				</button>
			</div>
		</div>
	</mat-dialog-actions>
</div>
