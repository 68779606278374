import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TenantDay } from '@core/enums';
import { CommonFacade } from '@core/facades/common.facade';
import { CommonItem, Tenant } from '@core/models';
import { environment } from '@environments/environment';
import { WEEK_DAYS } from '@feature/place-ad/place-ad.constant';
import { Observable } from 'rxjs';

interface DialogData {
	tenant: Tenant;
}

@Component({
	selector: 'findqo-set-tenant-view-date-dialog',
	templateUrl: './set-tenant-view-date-dialog.component.html',
	styleUrl: './set-tenant-view-date-dialog.component.scss',
})
export class SetTenantViewDateDialogComponent implements OnInit {
	constructor(
		@Inject(MAT_DIALOG_DATA) public data: DialogData,
		private dialogRef: MatDialogRef<SetTenantViewDateDialogComponent>,
		private commonFacade: CommonFacade
	) {}

	todayDate = new Date();
	maxDate: Date;

	readonly defaultAvatarImg = environment.defaultAvatarImg;
	allowedWeekdays: number[] = []; //TODO: update this value once API is ready
	selectedDate: Date | null;
	isShowTimeSlotsMobile = false;
	availibilitySlots$: Observable<CommonItem[]> = this.commonFacade.availiblitySlots$;

	timeSlotControl = new FormControl('', Validators.required);

	onSelectDate(): void {
		this.isShowTimeSlotsMobile = true;
	}

	private setMaxDate(months: number): void {
		this.maxDate = new Date();

		this.maxDate.setMonth(this.maxDate.getMonth() + months);
	}

	// Custom date filter function
	dateFilter = (date: Date) => {
		const day = date.getDay();
		return this.allowedWeekdays.includes(day);
	};

	onBackToCalendar(): void {
		this.isShowTimeSlotsMobile = false;
	}

	getDays() {
		this.allowedWeekdays =
			this.data?.tenant?.availabilitySlots?.length > 0
				? this.data?.tenant?.availabilitySlots.map((slots) => TenantDay[slots.reference.toUpperCase()])
				: WEEK_DAYS;
	}

	onSetViewDate(): void {
		if (this.selectedDate && this.timeSlotControl.valid) {
			const SELECTED_DATE = new Date(this.selectedDate);

			const DATE_STRING = `${SELECTED_DATE.getFullYear()}-${SELECTED_DATE.getMonth() > 9 ? SELECTED_DATE.getMonth() + 1 : `0${SELECTED_DATE.getMonth()}`}-${SELECTED_DATE.getDate() > 9 ? SELECTED_DATE.getDate() : `0${SELECTED_DATE.getDate()}`}`;

			const APPOINTMENT_DATA = {
				slotTime: {
					id: this.timeSlotControl.value,
				},
				viewingAt: Math.floor(SELECTED_DATE.getTime() / 1000),
				viewDate: DATE_STRING,
			};

			const updatedTenantData = { ...this.data.tenant, viewAppointment: { ...APPOINTMENT_DATA } };

			this.dialogRef.close(updatedTenantData);
		}
	}

	private patchData(): void {
		if (this.data?.tenant?.viewAppointment?.viewingAt) {
			this.selectedDate = new Date(+this.data?.tenant?.viewAppointment?.viewingAt * 1000);
			this.timeSlotControl.patchValue(this.data?.tenant?.viewAppointment?.slotTime?.id);
			this.isShowTimeSlotsMobile = true;
		}
	}

	ngOnInit(): void {
		this.getDays();
		this.setMaxDate(3);
		this.patchData();
	}
}
