import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatchingTenantApi } from '@core/data-access/apis/matching-tenant.api';
import { PlaceAdApi } from '@core/data-access/apis/place-ad.api';
import { TenantViewDateStatus } from '@core/enums';
import { CommonResponse, MobileLinks, Tenant, TenantViewDateSummary, VerifyPayment } from '@core/models';
import { SnackService } from '@core/services/snack.service';
import { SetTenantViewDateDialogComponent } from '@feature/general/set-tenant-view-date-dialog/set-tenant-view-date-dialog.component';
import { TenantViewDateSummaryDialogComponent } from '@feature/general/tenant-view-date-summary-dialog/tenant-view-date-summary-dialog.component';
import { MatchingTenantsState } from '@shared/states/matching-tenants-state';
import { Observable, catchError, switchMap, tap, throwError } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MatchingTenantsFacade {
	constructor(
		private dialog: MatDialog,
		private matchingTenantsState: MatchingTenantsState,
		private matchingTenantApi: MatchingTenantApi,
		private snackbar: SnackService,
		private placeAdApi: PlaceAdApi
	) {}

	get matchingTenantsList$(): Observable<Tenant[]> {
		return this.matchingTenantsState.matchingTenantsList$;
	}

	setMatchingTenantsList(tenantList: Tenant[]): void {
		this.matchingTenantsState.setMatchingTenantsList(tenantList);
	}

	openTenantViewDateDialog(tenantData: Tenant, adId: string, perPage = null): void {
		this.dialog.closeAll();
		this.dialog
			.open(SetTenantViewDateDialogComponent, {
				maxWidth: '800px',
				height: 'auto',
				width: '100%',
				disableClose: true,
				data: {
					tenant: { ...tenantData },
				},
			})
			.afterClosed()
			.subscribe((updatedTenantData) => {
				if (updatedTenantData) {
					this.openTenantViewDateSummary(updatedTenantData, adId, perPage);
				}
			});
	}

	openTenantViewDateSummary(tenantData: Tenant, adId: string, perPage = null): void {
		this.dialog
			.open(TenantViewDateSummaryDialogComponent, {
				maxWidth: '500px',
				height: 'auto',
				width: '100%',
				disableClose: true,
				data: {
					tenant: tenantData,
				},
			})
			.afterClosed()
			.subscribe((data: { status: TenantViewDateStatus }) => {
				if (data?.status && data?.status === TenantViewDateStatus.EDIT) {
					this.openTenantViewDateDialog(tenantData, adId, perPage);
				} else if (data?.status && data?.status === TenantViewDateStatus.CONFIRM) {
					this.confirmViewDate(tenantData, adId, perPage);
				}
			});
	}

	confirmViewDate(tenantData: Tenant, adId: string, perPage = null) {
		const BODY: TenantViewDateSummary = {
			adId: adId,
			tenantId: tenantData?.id,
			slotTimeId: tenantData?.viewAppointment?.slotTime?.id,
			viewAt: tenantData?.viewAppointment?.viewingAt,
			tenantName: tenantData?.name,
			tenantEmail: tenantData?.email,
			viewDate: tenantData?.viewAppointment?.viewDate,
		};

		const PARAMS = perPage ? { perPage: perPage } : null;

		this.matchingTenantApi
			.confirmTenantViewDate(adId, tenantData?.id, BODY)
			.pipe(
				switchMap(() => {
					this.snackbar.open({
						data: { translationKey: 'n3675', type: 'success', icon: 'check_circle' },
						panelClass: 'snackbar-success',
					});

					return this.matchingTenantApi.getMatchingTenantList(adId, PARAMS);
				})
			)
			.subscribe((tenantList: Tenant[]) => {
				this.matchingTenantsState.setMatchingTenantsList(tenantList);
			});
	}

	verifyPayment(body: VerifyPayment): Observable<Tenant[] | MobileLinks> {
		return this.placeAdApi.completePayment(body).pipe(
			switchMap(() => {
				return this.matchingTenantApi.getMatchingTenantList(body?.adId, { perPage: 3 }).pipe(
					catchError((err) => {
						this.matchingTenantsState.setMatchingTenantsList([]);

						return throwError(err);
					}),
					tap((tenantList: Tenant[]) => {
						this.matchingTenantsState.setMatchingTenantsList(tenantList);
					})
				);
			}),
			catchError((err) => {
				return throwError(err);
			})
		);
	}

	loadTenantList(adId: string, perPage = undefined): Observable<Tenant[]> {
		return this.matchingTenantApi.getMatchingTenantList(adId, { perPage }).pipe(
			tap((tenantList: Tenant[]) => {
				this.matchingTenantsState.setMatchingTenantsList(tenantList);
			})
		);
	}

	getMatchingTenantDetail(adId: string, tenantId: string): Observable<CommonResponse<Tenant>> {
		return this.matchingTenantApi.getMatchingTenantDetail(adId, tenantId);
	}

	getMobileAppLink(body: VerifyPayment): Observable<MobileLinks> {
		return this.placeAdApi.getMobileAppRedirectLink(body);
	}
}
