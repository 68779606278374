import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API } from '@core/constants';
import { CommonResponse, Tenant, TenantViewDateSummary } from '@core/models';
import { CommonUtil } from '@core/utils/common.util';
import { Observable, map } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class MatchingTenantApi {
	protected constructor(private readonly http: HttpClient) {}

	getMatchingTenantList(adId: string, body = null): Observable<Tenant[]> {
		return this.http
			.get<CommonResponse<Tenant[]>>(API.matchingTenant.getMatchingTenantList(adId), { params: CommonUtil.createHttpParams(body) })
			.pipe(map((response: { data: Tenant[] }) => response.data));
	}

	getMatchingTenantDetail(adId: string, tenantId: string): Observable<CommonResponse<Tenant>> {
		return this.http.get<CommonResponse<Tenant>>(API.matchingTenant.getMatchingTenantDetail(adId, tenantId));
	}

	confirmTenantViewDate(adId: string, tenantId: string, body: TenantViewDateSummary): Observable<CommonResponse<Record<string, never>>> {
		return this.http.post<CommonResponse<Record<string, never>>>(API.matchingTenant.submitTenantViewDate(adId, tenantId), body);
	}
}
